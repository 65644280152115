import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getUrl } from '../utils/getUrl';
import { message } from 'antd';
import { set } from 'lodash';
import { LoadingView } from './reusableComponents';
const TemplateComponent = ({ user ,repo }) => {
    const [templateList, setTemplateList] = useState([]);
    const [activeTemplate, setActiveTemplate] = useState(null);
    const [loading, setLoading] = useState(false);

    // Fetch template list when the component mounts or when the user changes
    useEffect(() => {
        if (user) {
            fetchTemplateList(user, setActiveTemplate, setTemplateList);
        }
    }, [user]);

    // Handle template selection
    const handleTemplateRadioChange = (event) => {
        const selectedTemplate = event.target.value;
        const selectedTemplateObj = templateList.find(
            (template) => template.filename === selectedTemplate
        );
        setActiveTemplate(selectedTemplateObj);
    };

    

    const handleSettingDefaultTemplate = async () => {
        if (activeTemplate) {
            try {
                const dynamicUrl = getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/v1/set_default_template`
                );
    
                const formData = new FormData();
                formData.append('repo_id', repo.repoid); // Assuming `repo.repoid` is available in your component
                formData.append('template_name', activeTemplate.filename);
    
                const response = await fetch(dynamicUrl, {
                    method: 'POST',
                    body: formData,
                });
    
                if (!response.ok) {
                    throw new Error('Failed to set default template');
                }
    
                const result = await response.json();
                message.success(result.message);
            } catch (error) {
                console.error('Error setting default template:', error.message);
            }
        } else {
            console.log('No template selected');
        }
    };

    // Fetch template list from the backend
    const fetchTemplateList = async (user, setActiveTemplate, setTemplateList) => {
        try {
            setLoading(true);
            if (!user) {
                throw new Error('User not found!');
            }

            // Fetch template list
            const tempUrl =getUrl( `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/gettemplate`);
            const response = await axios.get(tempUrl, {
                params: {
                    id: user.email,
                },
            });

            const templatesData = response.data;
            setTemplateList(templatesData);

            // Set the first template as active by default (optional)

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/v1/get_default_template_for_repo`
            );
            
            
            try {
                console.log("repo.repoid",repo.repoid)
                const response = await axios.get(dynamicUrl, { params: { repo_id: repo.repoid } });
                console.log("response",response)
                if (response.data.default_template) {
                    
                    setActiveTemplate({"filename":response.data.default_template});

                }
            } catch (error) {
                console.log("Error fetching default template:", error);
            }
            
            
        } catch (error) {
            console.error('Error fetching template list:', error.message);
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading&& <LoadingView loadingText="Loading templates" />}
            <div className="template-list m-8 max-h-md overflow-y-auto">
                {templateList.map((template, index) => (
                    <div
                        key={index}
                        className="flex items-center p-3 border rounded-xl mb-2"
                    >
                        <input
                            type="radio"
                            name="templateRadio"
                            value={template.filename}
                            checked={
                                activeTemplate &&
                                activeTemplate.filename === template.filename
                            }
                            onChange={handleTemplateRadioChange}
                            className="mr-2 cursor-pointer"
                        />
                        <label className="cursor-pointer text-base">
                            {template.filename}
                        </label>
                    </div>
                ))}
            </div>

            <div className="mb-8 flex justify-center">
                <button
                    onClick={handleSettingDefaultTemplate}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
                    disabled={!activeTemplate}
                >
                    Save 
                </button>
            </div>
        </div>
    );
};

export default TemplateComponent;