import React, { useState } from 'react';
import {
    faBugSlash,
    faCaretLeft,
    faCaretRight,
    faCheckCircle,
    faCheckDouble,
    faClock,
    faDatabase,
    faFile,
    faGear,
    faLayerGroup,
    faList,
    faNoteSticky,
    faQuestionCircle,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import { Button, Modal, Pagination } from '../reusableComponents';
import { getFileIcon, getFileIconColor, isValidJsonString } from '../../utils';
import { convertToQueriesArray } from './utils';

const getAnswerObject = (answerObject) => {
    let answer = answerObject;

    if (isValidJsonString(answer)) {
        let level = 0;
        const validJsonLevels = 5;
        while (isValidJsonString(answer) && level < validJsonLevels) {
            answer = JSON.parse(answer);
            level++;
        }
    } else {
        answer = answerObject;
    }

    return answer;
};

const SqlQueryComponent = ({ query }) => {
    if (!query) {
        return (
            <div className='text-icons-info'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2'
                />
                <span>Database query NOT generated</span>
            </div>
        );
    }

    return <>{query}</>;
};

const MultiQueriesComponent = ({ queries }) => {
    if (!queries || queries.length === 0) {
        return (
            <div className='text-icons-info'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2'
                />
                <span>No multi-queries fired for this answer</span>
            </div>
        );
    }

    const list = queries.map((query, index) => (
        <li key={index} className='mb-1'>
            {query}
        </li>
    ));
    return <ul className='list-disc pl-5'>{list}</ul>;
};

const SourceContent = ({ file, pageNumber, repoName }) => {
    if (!file)
        return (
            <div className='mr-8'>
                <FontAwesomeIcon
                    icon={faDatabase}
                    // color={getFileIconColor('.db')}
                    className='mr-2'
                />
                <span className='font-semibold mr-3'>Database Name :</span>

                <span>{repoName}</span>
            </div>
        );

    return (
        <div className='flex items-center'>
            <div className='w-1/2 truncate'>
                <FontAwesomeIcon icon={faFile} className='mr-2' />
                <span className='font-semibold mr-3'>File Name :</span>
                <FontAwesomeIcon
                    icon={getFileIcon(file)}
                    color={getFileIconColor(file)}
                    className='mr-2'
                />
                <span className='truncate text-base'>{file}</span>
            </div>

            <div className='pl-2'>
                <FontAwesomeIcon icon={faNoteSticky} className='mr-2' />
                <span className='font-semibold'>Page Number : </span>
                <span>{pageNumber ?? 'NA'}</span>
            </div>
        </div>
    );
};

const ChunkContent = ({ chunk }) => {
    if (!chunk) {
        return (
            <div className='h-[40vh] w-full flex items-center justify-center text-icons-info'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2'
                />
                <span>No chunk data</span>
            </div>
        );
    }

    return <div className='h-full w-full'>{chunk}</div>;
};

const ContentContainer = ({ title, icon, content }) => {
    return (
        <div className='border rounded-xl'>
            <div className='w-full flex items-center bg-backgrounds-primary100 p-3 rounded-t-xl shadow-inner'>
                <FontAwesomeIcon icon={icon} className='mr-2' />
                <span className='font-semibold'>{title}</span>
            </div>
            <div className='p-3 bg-backgrounds-white rounded-b-xl'>
                {content}
            </div>
        </div>
    );
};

export const ChatDebugModal = ({
    isSmallScreen,
    setShowDebugModal,
    chatObject,
    formattedAnswer,
    repoName,
}) => {
    const [activeChunkData, setActiveChunkData] = useState(
        chatObject.chunksData && Array.isArray(chatObject.chunksData)
            ? chatObject.chunksData[0]
            : null
    );
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSourceChange = (index) => {
        const newActiveChunk =
            chatObject.chunksData && Array.isArray(chatObject.chunksData)
                ? chatObject.chunksData[index]
                : null;
        setActiveChunkData(newActiveChunk);
        setActiveIndex(index);
    };

    const originalAnswer = getAnswerObject(chatObject.answer);
    const multiQueries = convertToQueriesArray(chatObject.queries);

    return (
        <Modal
            title={'Chat Debug View'}
            titleIcon={faBugSlash}
            iconColor={'icons-primary'}
            onClose={() => setShowDebugModal(false)}
        >
            <div className='w-[95vw] text-texts-secondary300'>
                <ReflexContainer orientation='vertical'>
                    {!isSmallScreen && (
                        <ReflexElement
                            className='left-pane mr-0.5 max-h-[79vh] overflow-auto'
                            minSize={300}
                            size={600}
                            maxSize={600}
                        >
                            <div className='pane-content w-full h-full bg-transparent p-3'>
                                <ContentContainer
                                    title={'Question'}
                                    icon={faQuestionCircle}
                                    content={chatObject.question}
                                />

                                <br />

                                <ContentContainer
                                    title={'Generated SQL Query'}
                                    icon={faDatabase}
                                    content={
                                        <SqlQueryComponent
                                            query={chatObject.sqlQuery}
                                        />
                                    }
                                />

                                <br />

                                <ContentContainer
                                    title={'Original Answer'}
                                    icon={faCheckCircle}
                                    content={
                                        <pre>
                                            {JSON.stringify(
                                                originalAnswer,
                                                null,
                                                2
                                            )}
                                        </pre>
                                    }
                                />

                                <br />

                                <ContentContainer
                                    title={'Formatted Answer'}
                                    icon={faCheckDouble}
                                    content={formattedAnswer}
                                />

                                <br />

                                <ContentContainer
                                    title={'Related Multi Queries'}
                                    icon={faClipboardList}
                                    content={
                                        <MultiQueriesComponent
                                            queries={multiQueries}
                                        />
                                    }
                                />

                                <br />

                                <ContentContainer
                                    title={'Timestamp'}
                                    icon={faClock}
                                    content={chatObject.timestamp}
                                />
                            </div>
                        </ReflexElement>
                    )}

                    {!isSmallScreen && (
                        <ReflexSplitter
                            style={{
                                border: 0,
                                background: 'white',
                                width: '6px',
                                zIndex: 10,
                                height: '79vh',
                            }}
                        />
                    )}

                    <ReflexElement className='right-pane ml-0.5 max-h-[79vh] overflow-auto'>
                        <div className='pane-content w-full h-full bg-transparent p-3'>
                            <ContentContainer
                                title={'Source'}
                                icon={faLayerGroup}
                                content={
                                    <SourceContent
                                        file={
                                            activeChunkData &&
                                            activeChunkData.actualFileName
                                                ? activeChunkData.actualFileName
                                                : null
                                        }
                                        pageNumber={
                                            activeChunkData &&
                                            activeChunkData.pageNumber
                                                ? activeChunkData.pageNumber
                                                : null
                                        }
                                        repoName={repoName}
                                    />
                                }
                            />

                            <br />

                            <div className='w-full h-full border rounded-xl z-0'>
                                <ReflexContainer orientation='horizontal'>
                                    <ReflexElement className='header'>
                                        <div className='pane-content w-full h-full'>
                                            <div className='w-full h-full flex items-center justify-between bg-backgrounds-primary100 p-3 rounded-t-xl shadow-inner'>
                                                <div className='flex items-center'>
                                                    <FontAwesomeIcon
                                                        icon={faList}
                                                        className='mr-2'
                                                    />
                                                    <span className='font-semibold'>
                                                        Chunk
                                                    </span>
                                                </div>

                                                {chatObject.chunksData &&
                                                    Array.isArray(
                                                        chatObject.chunksData
                                                    ) &&
                                                    chatObject.chunksData
                                                        .length > 0 && (
                                                        <div className='font-semibold'>
                                                            <span className='mx-1'>
                                                                {activeIndex +
                                                                    1}
                                                            </span>
                                                            /
                                                            <span className='mx-1'>
                                                                {
                                                                    chatObject
                                                                        .chunksData
                                                                        .length
                                                                }
                                                            </span>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </ReflexElement>

                                    <ReflexElement
                                        className=' bg-backgrounds-white rounded-b-xl'
                                        style={{
                                            minHeight: '45vh',
                                            height: '45vh',
                                            maxHeight: '45vh',
                                        }}
                                    >
                                        <div className='pane-content h-full w-full p-3 overflow-auto'>
                                            <ChunkContent
                                                chunk={
                                                    activeChunkData &&
                                                    activeChunkData.pageContent
                                                        ? activeChunkData.pageContent
                                                        : null
                                                }
                                            />
                                        </div>
                                    </ReflexElement>
                                </ReflexContainer>
                            </div>

                            <br />

                            {chatObject.chunksData &&
                                Array.isArray(chatObject.chunksData) &&
                                chatObject.chunksData.length > 0 && (
                                    <div className='h-full w-full px-3 py-2 border rounded-xl bg-backgrounds-primary100'>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex justify-center items-center text-texts-secondary300 truncate'>
                                                <FontAwesomeIcon
                                                    icon={faGear}
                                                    className='mr-2'
                                                />
                                                <div className='truncate font-semibold'>
                                                    Source Control
                                                </div>
                                            </div>
                                            <Pagination
                                                activeId={
                                                    chatObject.qid ?? null
                                                }
                                                itemsCount={
                                                    chatObject.chunksData &&
                                                    Array.isArray(
                                                        chatObject.chunksData
                                                    )
                                                        ? chatObject.chunksData
                                                              .length
                                                        : 0
                                                }
                                                itemsPerPage={1}
                                                handlePageChange={
                                                    handleSourceChange
                                                }
                                                previousLabel={
                                                    <Button
                                                        hoverText={
                                                            'Go to previous source'
                                                        }
                                                        icon={faCaretLeft}
                                                        type={'warning'}
                                                        disabled={
                                                            activeIndex === 0
                                                        }
                                                    />
                                                }
                                                nextLabel={
                                                    <Button
                                                        hoverText={
                                                            'Go to next source'
                                                        }
                                                        icon={faCaretRight}
                                                        type={'warning'}
                                                        disabled={
                                                            activeIndex ===
                                                            chatObject
                                                                .chunksData
                                                                .length -
                                                                1
                                                        }
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                        </div>
                    </ReflexElement>
                </ReflexContainer>
            </div>
        </Modal>
    );
};
