import React from 'react';

import { ExpandableTable } from '../../reusableComponents';
import { List } from 'antd';
import { ChatFormatType } from '../../../constants/chatFormatType';
import { isValidJsonString } from '../../../utils';
import { RepoType } from '../../../constants/repoType';
import { ChartDisplay } from '../chartDisplay';
import { getDate, getTime } from '../utils';
import { ChatDebugModal } from '../chatDebugModal';

let pagination = false;
let answerDisplayComponent = null;

export const AnswerContentView = ({
    chatObject,
    isDatabase,
    isSmallScreen,
    activeChart,
    setActiveChart,
    setEnableScrollToBottom,
    showDebugModal,
    setShowDebugModal,
    repoName,
}) => {
    const pageSize = isSmallScreen ? 3 : 5;

    if (chatObject) {
        if (
            isDatabase === RepoType.SQLLITE ||
            isDatabase === RepoType.POSTGRESS ||
            isDatabase === RepoType.MSSQL
        ) {
            // Database repositories

            let answer = chatObject.answer;

            if (isValidJsonString(answer)) {
                let level = 0;
                const validJsonLevels = 5;
                while (isValidJsonString(answer) && level < validJsonLevels) {
                    answer = JSON.parse(answer);
                    level++;
                }
            } else {
                answer = chatObject.answer;
            }

            if (answer.type === ChatFormatType.HTML) {
                answerDisplayComponent = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: answer.payload ?? answer,
                        }}
                        className='px-1'
                    />
                );
            } else if (answer.type === ChatFormatType.STRING) {
                answerDisplayComponent = chatObject.answer.payload
                    ? chatObject.answer.payload
                    : chatObject.answer;
            } else if (answer.type === ChatFormatType.TABLE) {
                const columnData = answer.payload.columns;
                const tableData = answer.payload.data;

                answerDisplayComponent = (
                    <ExpandableTable
                        columnData={columnData}
                        tableData={tableData}
                        pageSize={pageSize}
                        pagination={pagination}
                        isSmallScreen={isSmallScreen}
                        completeData={answer.payload.completeData}
                    />
                );
            } else if (chatObject.type === ChatFormatType.CHART) {
                answerDisplayComponent = (
                    <ChartDisplay
                        chatObject={chatObject}
                        activeChart={activeChart}
                        setActiveChart={setActiveChart}
                        isSmallScreen={isSmallScreen}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                    />
                );
            } else {
                answerDisplayComponent = `${chatObject.answer}`;
            }
        } else {
            // Non database repositories
            if (chatObject.type === ChatFormatType.TABLE) {
                const columnData = chatObject.answer.tableHead;

                const tableData = chatObject.answer.tableData;
                const tableFormattedData = tableData.map((rowData) =>
                    Object.values(rowData)
                );

                answerDisplayComponent = (
                    <ExpandableTable
                        columnData={columnData}
                        tableData={tableFormattedData}
                        pageSize={pageSize}
                        pagination={pagination}
                        isSmallScreen={isSmallScreen}
                    />
                );
            } // Non database repositories
            else if (chatObject.type === ChatFormatType.CHART) {
                answerDisplayComponent = (
                    <ChartDisplay
                        chatObject={chatObject}
                        activeChart={activeChart}
                        setActiveChart={setActiveChart}
                        isSmallScreen={isSmallScreen}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                    />
                );
            } else if (chatObject.type === ChatFormatType.LIST) {
                let data = [];

                if (chatObject.answer.listData) {
                    if (Array.isArray(chatObject.answer.listData)) {
                        data = chatObject.answer.listData;
                    } else {
                        data = [chatObject.answer.listData.toString()];
                    }
                } else {
                    data = [chatObject.answer.toString()];
                }

                answerDisplayComponent = (
                    <List
                        size='small'
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                );
            } else if (chatObject.type === ChatFormatType.MULTIPLE) {
                let title = '';
                let data = [];

                if (!Array.isArray(chatObject.answer)) {
                    title = 'Multiple Answers';
                    data = [chatObject.answer.toString()];
                } else {
                    title = `${chatObject.answer.length} Answer${
                        chatObject.answer.length > 1 ? 's' : ''
                    }`;
                    data = chatObject.answer;

                    if (chatObject.answer.length > 1) {
                        pagination = { pageSize: pageSize };
                    }
                }

                answerDisplayComponent = (
                    <List
                        header={
                            <div className='text-texts-secondary300 font-semibold text-center w-full'>
                                {title}
                            </div>
                        }
                        size='small'
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                        pagination={pagination}
                    />
                );
            } else if (chatObject.type === ChatFormatType.CUSTOM) {
                if (typeof chatObject.answer === ChatFormatType.STRING) {
                    answerDisplayComponent = chatObject.answer;
                } else {
                    answerDisplayComponent = chatObject.answer.toString();
                }
            } else {
                if (typeof chatObject.answer === ChatFormatType.STRING) {
                    answerDisplayComponent = chatObject.answer;
                } else {
                    answerDisplayComponent = chatObject.answer.toString();
                }
            }
        }

        return (
            <div className='w-full'>
                <div className='w-full text-slate-700'>
                    {answerDisplayComponent}
                </div>
                <div className='w-full flex justify-between items-center text-xs mt-2 text-texts-secondary300'>
                    <div>{getDate(chatObject.timestamp)}</div>
                    <div>{getTime(chatObject.timestamp)}</div>
                </div>
                {showDebugModal && (
                    <ChatDebugModal
                        setShowDebugModal={setShowDebugModal}
                        isSmallScreen={isSmallScreen}
                        chatObject={chatObject}
                        formattedAnswer={answerDisplayComponent}
                        repoName={repoName}
                    />
                )}
            </div>
        );
    }
};
