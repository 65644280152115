import React, { useEffect, useState } from 'react';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { LoadingView } from '../reusableComponents';
import { Modal } from '../reusableComponents';
import { AnswerContentView } from './answerView/answerContentView';
import { NoChatView } from './noChatView';
import { AnswerActionView } from './answerView/answerActionView';
import { QuestionActionView } from './questionView/questionActionView';
import { QuestionContentView } from './questionView/questionContentView';

const AnswerContainer = ({
    index,
    repoName,
    item,
    user,
    activeQuestion,
    sortedQnAData,
    handleChartFormat,
    handleCustomFormat,
    handleListFormat,
    handleTableFormat,
    setViewDatabaseQuery,
    setActiveDbQuery,
    setFlagIndex,
    setFlagMode,
    isLastAnswer,
    enableScrollToBottom,
    setEnableScrollToBottom,
    isDatabase,
    isSmallScreen,
    chatEndRef,
    isGenerating,
}) => {
    const [activeChart, setActiveChart] = useState(item.answer.chartType);
    const [showDebugModal, setShowDebugModal] = useState(false);
    return (
        <div className='flex w-full items-center justify-end'>
            <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-secondaryChat max-w-[600px] rounded-xl shadow-lg'>
                <AnswerActionView
                    index={index}
                    repoName={repoName}
                    chatObject={item}
                    user={user}
                    size={sortedQnAData.length}
                    handleChartFormat={handleChartFormat}
                    handleCustomFormat={handleCustomFormat}
                    handleListFormat={handleListFormat}
                    handleTableFormat={handleTableFormat}
                    setViewDatabaseQuery={setViewDatabaseQuery}
                    setActiveDbQuery={setActiveDbQuery}
                    flagged={item.flagged}
                    setFlagIndex={setFlagIndex}
                    setFlagMode={setFlagMode}
                    isLastAnswer={isLastAnswer}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                    isDatabase={isDatabase}
                    activeChart={activeChart}
                    setShowDebugModal={setShowDebugModal}
                    activeQuestion={activeQuestion}
                />
                <AnswerContentView
                    chatObject={item}
                    index={index}
                    activeQuestion={activeQuestion}
                    isSmallScreen={isSmallScreen}
                    isDatabase={isDatabase}
                    activeChart={activeChart}
                    setActiveChart={setActiveChart}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                    showDebugModal={showDebugModal}
                    setShowDebugModal={setShowDebugModal}
                    repoName={repoName}
                />

                {/* Dummy reference div, for auto scroll to this div (bottom) when display updates */}
                {enableScrollToBottom && index === sortedQnAData.length - 1 && (
                    <div
                        ref={chatEndRef}
                        className={!isGenerating ? 'mt-2' : ''}
                    />
                )}
            </div>
        </div>
    );
};

export const ChatDisplay = ({
    activeQuestion,
    questionsData,
    flagMode,
    repoName,
    setQuery,
    user,
    setFlagMode,
    setFlagIndex,
    handleChartFormat,
    handleTableFormat,
    handleListFormat,
    handleCustomFormat,
    isGenerating,
    fileToChat,
    isDatabase,
}) => {
    const chatEndRef = React.createRef();

    const scrollToBottom = (chatEndRef) => {
        chatEndRef.current?.scrollIntoView({
            behavior: isGenerating ? 'smooth' : 'instant',
        });
    };
    const [viewDatabaseQuery, setViewDatabaseQuery] = useState(false);
    const [activeDbQuery, setActiveDbQuery] = useState('');
    const [enableScrollToBottom, setEnableScrollToBottom] = useState(true);

    //responsiveness related
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        scrollToBottom(chatEndRef);
    }, [chatEndRef]);

    useEffect(() => {
        setEnableScrollToBottom(true);
    }, [isGenerating, repoName, fileToChat]);

    let chatList = [];
    if (questionsData) {
        // Sorting the chat according to time and date
        const sortedQnAData = [...questionsData.values()].sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
        });

        chatList = sortedQnAData.map((item, index) => {
            const isLastAnswer = index === sortedQnAData.length - 1;
            return (
                <div key={index}>
                    {/* Question Container */}
                    <div className='flex w-full items-center justify-start'>
                        <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-primary100 max-w-[600px] rounded-xl shadow-lg'>
                            <QuestionActionView
                                user={user}
                                chatObject={item}
                                setQuery={setQuery}
                            />
                            <QuestionContentView chatObject={item} />
                        </div>
                    </div>

                    {/* Answer Container */}
                    <AnswerContainer
                        user={user}
                        index={index}
                        repoName={repoName}
                        item={item}
                        activeQuestion={activeQuestion}
                        sortedQnAData={sortedQnAData}
                        handleChartFormat={handleChartFormat}
                        handleCustomFormat={handleCustomFormat}
                        handleListFormat={handleListFormat}
                        handleTableFormat={handleTableFormat}
                        setViewDatabaseQuery={setViewDatabaseQuery}
                        setActiveDbQuery={setActiveDbQuery}
                        setFlagIndex={setFlagIndex}
                        setFlagMode={setFlagMode}
                        isLastAnswer={isLastAnswer}
                        enableScrollToBottom={enableScrollToBottom}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                        isDatabase={isDatabase}
                        isSmallScreen={isSmallScreen}
                        chatEndRef={chatEndRef}
                        isGenerating={isGenerating}
                    />
                </div>
            );
        });
    }

    return chatList && chatList.length ? (
        <div className='w-full h-full p-4 flex flex-col justify-between'>
            {/* Database modal */}
            {viewDatabaseQuery && (
                <Modal
                    title={'Database Query'}
                    titleIcon={faDatabase}
                    iconColor={'icons-primary'}
                    onClose={() => setViewDatabaseQuery(false)}
                >
                    <div className='w-96 text-texts-secondary300 p-3'>
                        {activeDbQuery}
                    </div>
                </Modal>
            )}

            <div className='w-full h-full'>{chatList}</div>
            {isGenerating && (
                <div className='drop-shadow-sm mt-2'>
                    <LoadingView
                        inLine={true}
                        loadingText={'Generating Answer'}
                    />
                </div>
            )}
        </div>
    ) : (
        <NoChatView
            isGenerating={isGenerating}
            repoName={repoName}
            fileToChat={fileToChat}
        />
    );
};
