import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faPencil } from '@fortawesome/free-solid-svg-icons';

export const QuestionActionView = ({ user, chatObject, setQuery }) => {
    return (
        <div className='w-full ml-auto flex justify-between items-center border-b border-white pb-2 mb-2'>
            <div className='flex items-center justify-between text-texts-secondary300 truncate'>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-backgrounds-white mr-2'>
                    <FontAwesomeIcon
                        icon={faUserAlt}
                        className='text-sky-300'
                    />
                </div>
                <div className='font-semibold truncate text-base'>
                    {user.username}
                </div>
            </div>
            {/* Action Section */}
            <div className='text-texts-secondary300'>
                <button
                    onClick={() => {
                        setQuery(chatObject.question);
                    }}
                    title='Edit the question in chat box'
                    className='ml-4 hover:scale-110'
                >
                    <FontAwesomeIcon icon={faPencil} />
                </button>
            </div>
        </div>
    );
};
