import { DataType } from '../../enums';

export const getTime = (timestamp) => {
    const timeText = timestamp.split(',')[1];
    const timeData = timeText.split(':');
    const hours = timeData[0];
    const mins = timeData[1];
    const amPm = timeText.split(' ')[2];
    return `${hours}:${mins} ${amPm ? amPm : ''}`;
};

const splitDate = (date) => {
    const parts = date.split('/');
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    return {
        day,
        month,
        year,
    };
};

export const getDate = (timestamp) => {
    const date = timestamp.split(',')[0];
    const parts = date.split('/');
    const dateFormatted = parts[1] + '/' + parts[0] + '/' + parts[2];
    const dateObj = splitDate(dateFormatted);

    const dateNow = new Date().toLocaleDateString('en-GB').toString();
    const dateNowObj = splitDate(dateNow);

    if (
        dateNowObj.year === dateObj.year &&
        dateNowObj.month === dateObj.month &&
        dateNowObj.day === dateObj.day
    )
        return 'Today';
    if (
        dateNowObj.year === dateObj.year &&
        dateNowObj.month === dateObj.month &&
        dateNowObj.day - dateObj.day === 1
    )
        return 'Yesterday';

    return date;
};

export const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

export const convertToQueriesArray = (queriesString) => {
    let queriesArray = [];
    try {
        let parsedQueries = queriesString;

        // Manual conversion of the string to array
        if (typeof parsedQueries === DataType.STRING) {
            // Remove the outer square brackets and split by comma
            parsedQueries = parsedQueries
                .replace(/^\[|\]$/g, '') // Remove outer square brackets
                .split(/,\s*(?=['"])/) // Split by commas between strings
                .map(
                    (query) => query.replace(/^['"]|['"]$/g, '') // Remove quotes around each query
                );
        }

        // If it's an array, assign it to queriesArray
        if (Array.isArray(parsedQueries)) {
            queriesArray = parsedQueries;
        }
    } catch (error) {
        console.error('Failed to parse queries:', error);
    }

    return queriesArray;
};
