import React from 'react';
import { getDate, getTime } from '../utils';

export const QuestionContentView = ({ chatObject }) => {
    return (
        <>
            <div className='text-slate-700'>{chatObject.question}</div>
            <div className='flex justify-between items-between text-xs mt-2 text-texts-secondary300'>
                <div>{getDate(chatObject.timestamp)}</div>
                <div>{getTime(chatObject.timestamp)}</div>
            </div>
        </>
    );
};
