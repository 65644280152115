import React, { useEffect, useRef, useState } from 'react';
import { useAdmin } from '../../../context/AdminContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlag,
    faRobot,
    faTable,
    faList,
    faDatabase,
    faClipboardList,
    faEllipsisV,
    faChartPie,
    faBugSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Modal } from '../../reusableComponents';
import { convertToQueriesArray, truncateText } from '../utils';

const DisplayText = ({ text, maxLength }) => (
    <div className='font-semibold text-base'>
        {truncateText(text, maxLength)}
    </div>
);

export const AnswerActionView = ({
    index,
    repoName,
    chatObject,
    user,
    size,
    handleCustomFormat,
    handleListFormat,
    handleTableFormat,
    handleChartFormat,
    setViewDatabaseQuery,
    setActiveDbQuery,
    flagged,
    setFlagIndex,
    setFlagMode,
    isLastAnswer,
    setEnableScrollToBottom,
    isDatabase,
    activeChart,
    setShowDebugModal,
    activeQuestion,
}) => {
    //   const [previousFileToChat, setPreviousFileToChat] = useState('');
    //   useEffect(() => {
    //       // Update previousFileToChat only when fileToChat changes
    //       if (fileToChat !== undefined && fileToChat !== '') {
    //           setPreviousFileToChat(fileToChat);
    //       }
    //   }, [fileToChat]);
    const [sqlQuery, setSqlQuery] = useState('');
    const [showQueries, setShowQueries] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [queriesArray, setQueriesArray] = useState([]);
    const adminList = useAdmin();
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );

    const dropdownRef = useRef(null);

    const userSpecificActionObj = {
        chart: {
            label: 'Show in chart',
            icon: faChartPie,
            //iconColor: 'sky',
            action: () =>
                handleChartFormat(chatObject, isDatabase, activeChart),
        },
        table: {
            label: 'Show in tabular format',
            icon: faTable,
            //iconColor: 'sky',
            action: () => handleTableFormat(),
        },
        list: {
            label: 'Show in List format',
            icon: faList,
            //iconColor: 'sky',
            action: () => handleListFormat(),
        },
    };

    const dropDownList = Object.values(userSpecificActionObj);

    useEffect(() => {
        // Handler to check if screen width is less than or equal to 1024px
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        // Add event listener to window resize
        window.addEventListener('resize', handleResize);

        // Check the screen size initially
        handleResize();

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let isMounted = true; // Track whether the component is still mounted

        const loadResources = async () => {
            if (!chatObject.qid) return;

            // Load SQL query
            const query = chatObject.sqlQuery;
            if (isMounted && query) {
                setSqlQuery(query);
            }

            // Check if the user is an admin
            if (isMounted) {
                setIsAdmin(user && adminList.includes(user.email));
            }
        };

        loadResources();

        return () => {
            isMounted = false; // Cleanup on unmount
        };
    }, [chatObject.qid, adminList, user]);

    useEffect(() => {
        if (chatObject.queries) {
            const array = convertToQueriesArray(chatObject.queries);
            setQueriesArray(array);
        }
    }, [chatObject.queries]);

    return (
        <div className='w-full ml-auto flex justify-between items-center border-b border-white pb-2 mb-2'>
            <div className='flex items-center justify-between text-texts-secondary300 truncate'>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-backgrounds-white mr-2'>
                    <FontAwesomeIcon
                        icon={faRobot}
                        className='text-backgrounds-primary400'
                    />
                </div>
                <div className='font-semibold'>
                    <DisplayText
                        text={
                            !chatObject.fileToChat ||
                            chatObject.fileToChat === '' ||
                            chatObject.fileToChat === undefined
                                ? repoName
                                : chatObject.fileToChat
                        }
                        maxLength={28}
                    />
                </div>
            </div>

            {/* Action Section */}
            <div className='flex items-center w-fit h-full text-texts-secondary300'>
                {chatObject.queries && isAdmin && (
                    <>
                        <button
                            className='ml-4 hover:scale-110'
                            onClick={() => {
                                setEnableScrollToBottom(false);
                                setShowQueries(!showQueries);
                            }}
                            title='View related queries'
                        >
                            <FontAwesomeIcon
                                icon={faClipboardList}
                                style={{ fontSize: '18px' }}
                            />
                        </button>
                        {showQueries && (
                            <Modal
                                title={'Related Multi Queries'}
                                titleIcon={faClipboardList}
                                iconColor={'icons-primary'}
                                onClose={() => setShowQueries(false)}
                            >
                                <div className='w-96 text-texts-secondary300 p-3'>
                                    <div className='font-bold mb-2'>
                                        These are some multi queries that were
                                        asked from LLM:
                                    </div>
                                    {queriesArray && queriesArray.length > 0 ? (
                                        <ul className='list-disc pl-5'>
                                            {queriesArray.map(
                                                (query, index) => (
                                                    <li
                                                        key={index}
                                                        className='mb-1'
                                                    >
                                                        {query}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    ) : (
                                        <p>
                                            No multi-queries fired for this
                                            answer.
                                        </p>
                                    )}
                                </div>
                            </Modal>
                        )}
                    </>
                )}

                {(chatObject.chunksData.length || sqlQuery) &&
                    typeof chatObject.answer === 'string' && (
                        <div className='text-texts-secondary300'>
                            {sqlQuery && (
                                <button
                                    className='ml-4 hover:scale-110'
                                    onClick={() => {
                                        setEnableScrollToBottom(false);
                                        setViewDatabaseQuery(true);
                                        setActiveDbQuery(sqlQuery);
                                    }}
                                    title='View query'
                                >
                                    <FontAwesomeIcon icon={faDatabase} />
                                </button>
                            )}

                            {isLastAnswer && (
                                <button
                                    className='ml-4 hover:scale-110'
                                    onClick={userSpecificActionObj.chart.action}
                                    disabled={!chatObject.answer}
                                    title='Convert answer into chart format'
                                >
                                    <FontAwesomeIcon icon={faChartPie} />
                                </button>
                            )}

                            {!sqlQuery && isLastAnswer && !isSmallScreen && (
                                <>
                                    <button
                                        className='ml-4 hover:scale-110'
                                        onClick={
                                            userSpecificActionObj.table.action
                                        }
                                        disabled={!chatObject.answer}
                                        title='Convert answer into tabular format'
                                    >
                                        <FontAwesomeIcon icon={faTable} />
                                    </button>
                                    <button
                                        className='ml-4 hover:scale-110'
                                        onClick={
                                            userSpecificActionObj.list.action
                                        }
                                        disabled={!chatObject.answer}
                                        title='Convert answer into list format'
                                    >
                                        <FontAwesomeIcon icon={faList} />
                                    </button>
                                </>
                            )}

                            {activeQuestion && isLastAnswer && isAdmin && (
                                <button
                                    className='ml-4 hover:scale-110'
                                    onClick={() => setShowDebugModal(true)}
                                    disabled={!isAdmin}
                                    title='Debug Answer'
                                >
                                    <FontAwesomeIcon icon={faBugSlash} />
                                </button>
                            )}
                        </div>
                    )}

                {/* flag */}
                <button
                    title='Flag this response'
                    onClick={() => {
                        setEnableScrollToBottom(false);
                        setFlagMode(true);
                        setFlagIndex(chatObject.qid);
                    }}
                    className='ml-4 hover:scale-110'
                >
                    <FontAwesomeIcon
                        icon={faFlag}
                        className={
                            flagged
                                ? 'text-buttons-alert400'
                                : 'text-texts-secondary300'
                        }
                    />
                </button>

                {/* Dropdown for small screens */}
                {!sqlQuery && isLastAnswer && isSmallScreen && (
                    <div
                        className='relative inline-block text-left'
                        ref={dropdownRef}
                    >
                        <Dropdown
                            dropDownList={dropDownList}
                            icon={<FontAwesomeIcon icon={faEllipsisV} />}
                            showButtonText={false}
                            buttonText={null}
                            buttonHoverText={'More options'}
                            buttonClassName={
                                'ml-4 hover:scale-110 focus:outline-none focus:outline-none'
                            }
                            listClassName={''}
                            backgroundColor={''}
                            textColor={'green'}
                            disabled={false}
                            customClass={true}
                            placement={'left'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
