import React from 'react';
import { LoadingView } from '../reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

export const NoChatView = ({ repoName, isGenerating, fileToChat }) => {
    if (isGenerating) {
        return (
            <div className='flex w-full h-full items-end justify-center drop-shadow-sm mt-2'>
                <LoadingView inLine={true} loadingText={'Generating Answer'} />
            </div>
        );
    }

    return (
        <div className='flex flex-col h-[60vh] w-full items-center justify-center my-4 relative'>
            <FontAwesomeIcon
                icon={faComments}
                className={`text-icons-primary100
            w-16 h-16
            md:w-24 md:h-24
            lg:w-32 lg:h-32
            `}
            />

            <div className='flex items-center justify-center text-texts-secondary300 text-xl mt-6'>
                <div className='break-all p-2'>
                    Start chatting with{' '}
                    {fileToChat === '' || fileToChat === undefined
                        ? repoName
                        : fileToChat}
                    's model
                </div>
            </div>
        </div>
    );
};
