import React from 'react';
import { Chart } from 'react-google-charts';
import { DataType } from '../../enums';
import { ChartTypes } from '../reportLayouts/constants';
import { Button } from '../reusableComponents';
import {
    faChartPie,
    faChartColumn,
    faChartBar,
    faChartLine,
} from '@fortawesome/free-solid-svg-icons';

const ChartButtonGroup = ({
    activeChart,
    setActiveChart,
    isSmallScreen,
    setEnableScrollToBottom,
}) => {
    const buttonData = [
        {
            icon: faChartBar,
            type: ChartTypes.BAR_CHART,
            buttonText: 'Bar',
            hoverText: `Convert to Bar chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.BAR_CHART);
            },
        },
        {
            icon: faChartColumn,
            type: ChartTypes.COLUMN_CHART,
            buttonText: 'Column',
            hoverText: `Convert to Column chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.COLUMN_CHART);
            },
        },
        {
            icon: faChartLine,
            type: ChartTypes.LINE_CHART,
            buttonText: 'Line',
            hoverText: `Convert to Line chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.LINE_CHART);
            },
        },
        // {
        //     icon: faChartArea,
        //     type: ChartTypes.AREA_CHART,
        //     buttonText: 'Area',
        //     hoverText: `Convert to Area chart format`,
        //     action: () => {
        //         setEnableScrollToBottom(false);
        //         setActiveChart(ChartTypes.AREA_CHART);
        //     },
        // },
        {
            icon: faChartPie,
            type: ChartTypes.PIE_CHART,
            buttonText: 'Pie',
            hoverText: `Convert to Pie Chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.PIE_CHART);
            },
        },
    ];

    const renderButtonGroup = buttonData.map((data) => (
        <Button
            text={data.buttonText}
            type={'success'}
            onClick={data.action}
            icon={data.icon}
            hoverText={data.hoverText}
            disabled={false}
            active={activeChart === data.type}
            className={`${isSmallScreen ? 'mr-1' : 'ml-1'} mb-1`}
        />
    ));
    return (
        <div
            className={`w-full flex ${
                isSmallScreen ? 'flex-col' : ''
            } items-center justify-between mb-1`}
        >
            <div className='mr-2 font-semibold text-texts-secondary300'>
                Charts
            </div>
            <div className=' flex items-center justify-start mb-1 flex-wrap'>
                {renderButtonGroup}
            </div>
        </div>
    );
};

export const ChartDisplay = ({
    chatObject,
    activeChart,
    setActiveChart,
    isSmallScreen,
    setEnableScrollToBottom,
}) => {
    if (typeof chatObject.answer.data === DataType.STRING) {
        return chatObject.answer.data.toString();
    }

    const columnData = chatObject.answer.columns;
    const data = chatObject.answer.data;

    if (Array.isArray(data) && Array.isArray(data[0]) && data[0].length > 0) {
        let columnDataIsString = 0;
        data[0].forEach((element) => {
            if (typeof element === DataType.STRING) columnDataIsString++;
        });

        if (columnDataIsString > 1) {
            return <div>No chart possible for this data representation.</div>;
        }

        const chartData = [columnData, ...data];
        const chartType = activeChart
            ? activeChart
            : chatObject.answer.chartType;
        let options = chatObject.answer.options;
        if (chartType === ChartTypes.BAR_CHART) {
            options = {
                ...options,
                hAxis: options.vAxis,
                vAxis: options.hAxis,
            };
        }

        return (
            <div className='w-full flex flex-col'>
                <ChartButtonGroup
                    activeChart={activeChart}
                    setActiveChart={setActiveChart}
                    isSmallScreen={isSmallScreen}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                />
                <Chart
                    chartType={chartType}
                    data={chartData}
                    options={{
                        ...options,
                        legend: {
                            position: 'top',
                            alignment: 'end',
                        },
                    }}
                    width={'100%'}
                    height={'fit-content'}
                    chartPackages={['corechart', 'controls']}
                />
            </div>
        );
    }
};
